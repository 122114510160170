<template>
  <main id="content" role="main">
    <div class="position-relative z-index-2">

      <!-- Content -->
      <div class="d-md-flex">
        <div class="container d-md-flex align-items-md-center min-vh-md-100 text-center space-2 space-top-md-3 space-top-lg-2">
          <div class="w-lg-85 mx-lg-auto">
            <!-- Info -->
            <div class="mt-5 mb-7">
              <h1 class="display-4 text-white mb-3">Savelocal for <br class="d-md-none">nonprofits</h1>
              <p class="lead text-white mb-8">
                Do your conservation project(s) make a <span class="text-primary">visible</span> impact<br>within a <span class="text-primary">clearly defined</span> geographical area?
              </p>
                <a class="btn btn-primary" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScuHSGLRkKTnns-SFXOlXTdpCSorGHFPZZAgD6053WRqkJieA/viewform?usp=sf_link">
                  Sign up form&nbsp;&nbsp;<i class="fa fa-external-link-alt" style="opacity:0.3"></i>
                </a>
                <p class="text-white mt-5"><i class="fa fa-info-circle"></i> Please get in touch if you are not sure if your project is a fit with savelocal.</p>
            </div>
            <!-- End Info -->
          </div>
        </div>
      </div>
      <!-- End Content -->


      <div class="position-absolute bottom-0 right-0 left-0 text-center">
        <div class="container-fluid text-white py-5" style="opacity:0.3">
          <i class="fa fa-map-marker-alt"></i>
          <span> Roatán, Honduras - Photo by <a href="https://unsplash.com" target="_blank" style="color:inherit;text-decoration:underline">Erin Simmons</a></span>
        </div>
      </div>
    </div>

    <div class="position-absolute top-0 right-0 bottom-0 left-0 bg-img-hero" style="background-image:linear-gradient(to bottom, rgba(19, 24, 54, 0.60), rgba(19, 24, 54, 0.30)),url(./assets/img/cover-turtle.jpg);"></div>
  </main>
</template>

<style lang="scss" scoped>

</style>
